$breakpoints: (
    phone: 768px,
) !default;

.chat-item-self {
    background-color: #2b6cb0;
    color: white;
}

.chat-item-other {
    background-color: #ececec;
    color: black;
}

.chat-item {
    font-family: acumin-pro, sans-serif;
}

.infobox h1 {
    font-size: 1.8em;
}


.infobox h2 {
    font-size: 1.5em;
}

.event-page {
    display: grid;
    grid-template-areas:
        "player"
        "content";

    &--has-chat {
        grid-template-areas:
            "player chat"
            "content content";
        grid-template-columns: 1fr minmax(22rem, 20vw);
    }

    &__header {
        grid-area: header;
    }

    &__notice {
        grid-area: notice;
    }

    &__player {
        grid-area: player;
    }

    &__chat {
        grid-area: chat;
        max-height: 100%;
    }

    &__content {
        grid-area: content;
    }

    .header {
        position: relative;

        &__background {
            display: flex;
            max-height: 50vh;
            min-height: 650px;
            height: 100%;
            position: relative;
            overflow: hidden;

            @media (max-width: 768px) {
                //min-height: 100vw;
            }

            img {
                object-fit: cover;
                object-position: 0 20%;
                width: 100%;
                max-width: 100%;
                padding: 0;
                display: block;
                height: auto;
            }
        }

        &__backgroundactivestream {
            display: flex;
            max-height: 30vh;
            min-height: 300px;
            position: relative;
            overflow: hidden;

            @media (max-width: 768px) {
                min-height: 40vh;
                max-height: 40vh;
            }

            img {
                object-fit: cover;
                object-position: 0 20%;
                width: 100%;
                max-width: 100%;
                padding: 0;
                display: block;
                height: auto;
            }
        }

        &__content {
            position: absolute;
            bottom: 2em;
            width: 100%;
            margin-top: -2rem;
            display: block;

            &-wrapper {
                max-width: 75rem;
                margin-right: auto;
                margin-left: auto;
                display: flex;
                flex-direction: column;

                .event-title {
                    h1 {
                        width: fit-content;
                    }
                }

                .event-times {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    padding: 1rem 2rem 1rem 1rem;

                    & > * {
                        margin-right: 2rem;
                    }
                }
            }
        }
    }

    article,
    .event-page__products {
        h1 {
            font-size: 3rem;
        }

        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1rem;
        }

        p {
            padding-top: 1rem;
        }

        a {
            text-decoration: underline;
            color: #2b6cb0;

            &:hover {
                color: #4299e1;
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1300px) {
    .event-page {
        &__content {
            padding-left: 1rem;
            padding-right: 1rem;

            flex-direction: column-reverse;
        }
    }
}

@media (max-width: 980px) {
    .event-page {
        grid-template-columns: auto;
        grid-template-areas:
            "header"
            "notice"
            "player"
            "chat"
            "content";

        &--has-chat {
            grid-template-rows: auto auto auto 45vh auto;
        }

        &__player {
            margin-bottom: 0;
        }

        &__chat {
            height: 100% !important;
        }

        .header {
            &__background {
                img {
                    object-position: center;
                }
            }
        }
    }
}
