
@font-face {
    font-family: ubuntu-bd;
    src: url("graphics/fonts/UbuntuRegular.woff2");
}

@font-face {
    font-family: ubuntu-bd-bold;
    src: url("graphics/fonts/UbuntuBold.woff2");
}

@font-face {
    font-family: ubuntu-bd-medium;
    src: url("graphics/fonts/UbuntuMedium.woff2");
}

body {
    background-color: #242424;
    font-family: ubuntu-bd, Ubuntu, Arial, sans-serif;
}

.font-ubuntu-bold {
    font-family: ubuntu-bd-bold, Ubuntu, Arial, sans-serif;
    font-weight: 900;
}

h1 {
    font-size: xx-large;
}

.center-container {
    position: relative;
}

.center-holder {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}



.jw-title {
    position: relative;
    height: 100%;

}
.jw-title-primary {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
    padding-top: 10vw!important;
    font-size: 1em!important;
}

@media (max-width: 768px) {
    .jw-title-primary {
        padding-top: 10em!important;
        font-size: 0.5em!important;
    }
}


.transition-component {
    transition: opacity 500ms ease-in-out;
}



.container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
}

.background-image {
    background-image: url('graphics/bg/FREYR_WAVE.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}

.bg-poster {
    background-image: url('graphics/bg/nysegirl.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.5;
}

#jwplayer {

}
